.about {
  flex-direction: column;
  margin-top: 3em;
  white-space: pre;
}

.about__name {
  color: var(--clr-primary);
}

.about__name-string {
  text-wrap: wrap;
  text-align: center;
}

.about__role {
  margin-top: 1.2em;
  text-wrap: wrap;
  text-align: center;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
  text-align: center;
  text-wrap: wrap;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about__contact {
  display: flex;
  justify-content: center;

}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}



@media (max-width: 600px) {
  .app .about {
    align-items: center;
    margin-top: 2em;
  }
}